
import { reactive, defineComponent, ref, computed, onMounted, onBeforeMount, } from "vue";
import ApiService from "@/core/services/ApiService";
import { showErrorPopup } from "@/core/helpers/common-helper";
import { checkPermission } from "@/router/authentication";
import { useRouter } from "vue-router";

interface Account {
  account_number: string;
}

export default defineComponent({
  setup() {
    const submitButton = ref<null | HTMLButtonElement>(null);
    const router = useRouter();

    const accountSuccess = ref(false);

    const data = ref()
    const isValidatingSource = ref(false);
    const sourceAccount = ref();

    const account = reactive<Account>({
      account_number: ''
    });

    const selectedSourceAccount = computed(() => {
      return sourceAccount.value.find((i) => {
            if(i.account_number == account.account_number) return i
          })
    })

    const submitAccount = () => {

      submitButton.value ? (submitButton.value.disabled = true) : null;
      submitButton.value?.setAttribute("data-kt-indicator", "on");

      const payload = {
        account_number: account.account_number,
      };

      ApiService.setHeader();
      ApiService.post(
        `app/finance/account/balance`,
        payload as any
      )
        .then((response) => {
          accountSuccess.value = true;
          data.value = response.data.data
        })
        .catch(({ response }) => {
          account.account_number = '';

          if (Object.prototype.hasOwnProperty.call(response.data, "data")) {
            if (response.data.data.length > 0) {
              for (const key in response.data.data) {
                showErrorPopup({
                  title: response.data.rc,
                  text: response.data.data[key],
                  message: response.data.data[key],
                })
              }
            } else {
              showErrorPopup({
                title: response.data.rc,
                text: response.data.message,
                message: response.data.message,
              })
            }
          } else {
            showErrorPopup({
              title: response.data.rc,
              text: response.data.message,
              message: response.data.message,
            })
          }
        })
        .finally(() => {
          submitButton.value ? (submitButton.value.disabled = false) : null;
          submitButton.value?.removeAttribute("data-kt-indicator");
        });
    };
    
    const resetDisplay = () => {
      accountSuccess.value = false;
    }

    const clearAccount = () => {
      accountSuccess.value = false;
      account.account_number = '';
    }

    const dynamicAccountInfo = computed(() => {
      if (Object.prototype.hasOwnProperty.call(data.value, "accountInfos")) {
        return data.value.accountInfos[0]
      }

      return data.value.accountInfo[0]
    })

    const getSourceAccount = () => {
      isValidatingSource.value = true;

      ApiService.setHeader();
      ApiService.getWithoutSlug(
        `app/enum/channel-pool-account`,
        "",
        {}
      )
        .then((response) => {
          if (response.data.data.length > 0) {
            ApiService.getWithoutSlug(
              `app/enum/account`,
              "",
              {
                params: {
                  account_numbers: response.data.data,
                  type: 'internal'
                }
              }
            )
              .then((resp) => {
                sourceAccount.value = resp.data.data;

                
              })
              .finally(() => {
                isValidatingSource.value = false;
              });
          }
        })
        .catch(() => {
          isValidatingSource.value = false;
        });
    };

    onBeforeMount(() => {
      if (!checkPermission('account-balance').isGranted) {
        router.push({ name: "app" });
      }
    })

    onMounted(() => {
      getSourceAccount()
    })

    return {
      account,
      submitButton,
      accountSuccess,
      clearAccount,
      submitAccount,
      data,
      dynamicAccountInfo,
      isValidatingSource,
      sourceAccount,
      selectedSourceAccount,
      resetDisplay,
    }
  }
});
